<template>
    <scada-nav-component :isPV="true" v-if="isAdmin"/>
    <router-view />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'; 
import ScadaNavComponent from '@/components/nav/SCADANavComponent.vue';

@Options({
    components: {
        ScadaNavComponent
    },
    data()
    {
        return {
            isAdmin: false
        }
    },
    created()
    {
        if(localStorage.getItem('role') == 'ROLE_ADMIN')
        {
            this.isAdmin = true
        }
    }
})
export default class PVDetailView extends Vue {}
</script>
