import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "command-setup"
}
const _hoisted_2 = { class: "command-setup-sms-recivers" }
const _hoisted_3 = { class: "command-setup-add-sms-recivers" }
const _hoisted_4 = { class: "command-setup-email-recivers" }
const _hoisted_5 = { class: "command-setup-add-sms-recivers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setup_modal = _resolveComponent("setup-modal")!
  const _component_CSpinner = _resolveComponent("CSpinner")!

  return (_ctx.isLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", null, "Komenda: " + _toDisplayString(_ctx.command.commandName), 1),
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(" Akutalni Odbiorcy SMS: "),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.command.smsNumbers, (smsNumber) => {
              return (_openBlock(), _createElementBlock("li", null, _toDisplayString(smsNumber), 1))
            }), 256))
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_setup_modal, {
              options: {
                    buttonText: 'Dodaj odbiorców',
                    title: 'Odbiorcy SMS'
                },
              receivers: _ctx.command.smsNumbers == null ? [] : _ctx.command.smsNumbers.slice(),
              isSmsReceiver: true,
              onUpdateReceivers: _ctx.updateReceivers
            }, null, 8, ["receivers", "onUpdateReceivers"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createTextVNode(" Akutalni Odbiorcy Email: "),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.command.emails, (email) => {
              return (_openBlock(), _createElementBlock("li", null, _toDisplayString(email), 1))
            }), 256))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_setup_modal, {
              options: {
                    buttonText: 'Dodaj odbiorców',
                    title: 'Odbiorcy SMS'
                },
              receivers: _ctx.command.emails == null ? [] : _ctx.command.emails.slice(),
              userEmails: _ctx.users.userEmails,
              onUpdateReceivers: _ctx.updateReceivers
            }, null, 8, ["receivers", "userEmails", "onUpdateReceivers"])
          ])
        ])
      ]))
    : (_openBlock(), _createBlock(_component_CSpinner, {
        key: 1,
        color: "success",
        class: "spinner"
      }))
}