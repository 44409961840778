<template>
<div class="contener">
  Admin Display
  <br>
  <date-picker-modal-component @pickedDates="fetchFromPickedDate"/>
  <br>
  Dane z: {{ today }}
  <hr>
  <div class="wind-turbines">
    <h2>Turbiny</h2>
    <div class="wind-turbines container" v-show="isLoaded">
      <h5>
        Produkcja: <b>{{ vensys.totalProduction }} kWh</b>
      </h5>
      <vensys-view :turbines="vensys.turbines"/>
    </div>
  </div>
  <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
  <hr>
  <div class="pv-farms">
    <h2>FotFarm</h2>
    <div class="pv-farms-container" v-show="isLoaded">
      <h5>Produkcja: <b>{{ pv.sungrow.totalProduction }} kWh</b></h5>
      <PVFarmView :pvFarms="pv.sungrow.pvInstallations" />
    </div>
  </div>
  <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
  <hr>
  <div class="pv">
    <h2>PV</h2>
    <div class="pv-huawei" >
      <h3>Huawei</h3>
      <div class="pv-huawei-container" v-show="isLoaded">
        <h5>Produkcja: <b>{{ pv.huawei.totalProduction }} kWh</b></h5>
        <h5>Zużycie: <b>{{ pv.huawei.totalUsedPower }} kWh</b></h5>
        <installations-view :installations="pv.huawei.pvInstallations" />
      </div>
    </div>
    <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
    <div class="pv-solarman">
      <h3>Solarman</h3>
      <div class="pv-solarman-container" v-show="isLoaded">
        <h5>Produkcja: <b>{{ pv.solarman.totalProduction }} kWh</b></h5>
        <h5>Zużycie: <b>{{ pv.solarman.totalUsedPower }} kWh</b></h5>
        <installations-view :installations="pv.solarman.pvInstallations" />
      </div>
    </div>
  </div>
  <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { BodyParams } from '@/interfaces/body';
import { CSpinner } from '@coreui/vue';
import InstallationsView from '@/components/pv/InstallationsView.vue';
import VensysView from '@/components/turbines/VensysView.vue';
import PVFarmView from '@/components/pv/farm/PVFarmView.vue';
import DatePickerModalComponent from '@/components/DatePickerModalComponent.vue';
import store from '@/store';


@Options({
  components: {
    InstallationsView, VensysView, CSpinner, DatePickerModalComponent, PVFarmView
  },
  data() {
    return {
      data: null,
      isLoaded: false,
      showDatePicker: false,
      today: '',
      vensys: {
        totalProduction: 0,
        turbines: []
      },
      pv: {
        huawei: {
          totalProduction: 0,
          totalUsedPower: 0,
          pvInstallations: [
            {
              success: false,
              installation: '',
              msg: '',
              powerFromGrid: 0,
              powerFromPV: 0,
              powerToTheGrid: 0,
              totalUsedPower: 0
            }
          ]
        },
        solarman: {
          totalProduction: 0,
          totalUsedPower: 0,
          pvInstallations: [
            {
              success: false,
              installation: '',
              msg: '',
              powerFromGrid: 0,
              powerFromPV: 0,
              powerToTheGrid: 0,
              totalUsedPower: 0
            }
          ]
        },
        sungrow: {
          totalProduction: 0,
          pvInstallations: [
            {
              id: 0,
              installation: '',
              totalProduction: 0,
              unit: ''
            }
          ]
        }
      }
    }
  },
  methods: {
    async getVensysProduction(dates: BodyParams|null)
    {
      let body = {};
      if(dates != null)
      {
        body = {
          'startDate': dates.startDate,
          'endDate': store.getters.getDate('+1day', dates.endDate).substring(0,10)
        }
      }else
      {
        body = {
          'startDate': store.getters.getDate().substring(0,10),
          'endDate': store.getters.getDate('+1day', null).substring(0,10)
        }
      }
      let data = await store.dispatch({
        'type': 'postRequest',
        'link': 'production/vensys',
        'body': body
      })
      if(!data.success)
      {
        alert('Problem while retriving data from API')
      }
      this.$store.commit('setVensysTurbinesProduction', await data);
      this.vensys.turbines = store.getters.getVensysTurbinesProduction().turbines;
      this.vensys.totalProduction =  Math.round((store.getters.getVensysTurbinesProduction().totalProduction * 100))/100;
    },
    async getHuaweiProduction(dates: BodyParams|null)
    {
      let body = {};
      if(dates != null)
      {
        body = {
          'startDate': dates.startDate,
          'endDate': dates.endDate
        }
      }else
      {
        body = {
          'startDate': store.getters.getDate().substring(0,10),
          'endDate': store.getters.getDate().substring(0,10)
        }
      }
      
      let data = await store.dispatch({
        'type': 'postRequest',
        'link': 'production/pv/huawei',
        'body': body
      })

      if(!data.success)
      {
        alert('Problem while retriving data from API')
      }

      this.$store.commit('setHuaweiProduction', await data);
      this.pv.huawei = store.getters.getHuaweiProduction();
      this.pv.huawei.totalProduction =  Math.round((store.getters.getHuaweiProduction().totalProduction * 100))/100;
      this.pv.huawei.totalUsedPower =  Math.round((store.getters.getHuaweiProduction().totalUsedPower * 100))/100;
    },
    async getSolarmanProduction(dates: BodyParams|null)
    {
      let body = {};
      if(dates != null)
      {
        body = {
          'startDate': dates.startDate,
          'endDate': dates.endDate
        }
      }else
      {
        body = {
          'startDate': store.getters.getDate().substring(0,10),
          'endDate': store.getters.getDate().substring(0,10)
        }
      }
      
      let data = await store.dispatch({
        'type': 'postRequest',
        'link': 'production/pv/solarman',
        'body': body
      })

      if(!data.success)
      {
        alert('Problem while retriving data from API')
      }

      this.$store.commit('setSolarmanProduction', await data);
      this.pv.solarman = store.getters.getSolarmanProduction();
      this.pv.solarman.totalProduction =  Math.round((store.getters.getSolarmanProduction().totalProduction * 100))/100;
      this.pv.solarman.totalUsedPower =  Math.round((store.getters.getSolarmanProduction().totalUsedPower * 100))/100;
    },
    async getSungrowProduction(dates: BodyParams|null)
    {
      let body = {};
      if(dates != null)
      {
        body = {
          'startDate': dates.startDate,
          'endDate': dates.endDate
        }
      }else
      {
        body = {
          'startDate': store.getters.getDate().substring(0,10),
          'endDate': store.getters.getDate().substring(0,10)
        }
      }
      
      let data = await store.dispatch({
        'type': 'postRequest',
        'link': 'production/pv/sungrow',
        'body': body
      })

      if(!data.success)
      {
        alert('Problem while retriving data from API')
      }

      this.$store.commit('setSungrowProduction', await data);
      this.pv.sungrow = store.getters.getSungrowProduction();
      this.pv.sungrow.totalProduction =  Math.round((store.getters.getSungrowProduction().totalProduction * 100))/100;
    },
    async fetchFromPickedDate(pickedDates: BodyParams)
    {
      this.isLoaded = false;
      const endDate = new Date(pickedDates.endDate);
      const startDate = new Date(pickedDates.startDate);
      const timeDifference = endDate.getTime() - startDate.getTime();
      const differenceInDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
      if(differenceInDays > 31)
      {
        alert('Zakres dat nie może przekroczyć 31 dni')
        this.isLoaded = true;
        return;
      }
      if(pickedDates.startDate == pickedDates.endDate)
      {
        this.today = `${pickedDates.startDate}`
      }else
      {
        this.today = `Od: ${pickedDates.startDate} do ${pickedDates.endDate}`
      }

      const test = {...pickedDates};
      await this.getVensysProduction(test)
      await this.getHuaweiProduction(test)
      await this.getSolarmanProduction(test)
      await this.getSungrowProduction(test)
      this.isLoaded = true;
    }
  },
  async created() {
    this.today = store.getters.getDate().substring(0,10);
    if(store.getters.getVensysTurbinesProduction().turbines == null && store.getters.getSolarmanProduction().pvInstallations == null && store.getters.getHuaweiProduction().pvInstallations == null && store.getters.getSungrowProduction().pvInstallations == null)
    {
      await this.getVensysProduction(null)
      await this.getHuaweiProduction(null)
      await this.getSolarmanProduction(null)
      await this.getSungrowProduction(null)
    }
    //Vensys
    this.vensys.turbines = store.getters.getVensysTurbinesProduction().turbines;
    this.vensys.totalProduction =  Math.round((store.getters.getVensysTurbinesProduction().totalProduction * 100))/100;
    //Solarman
    this.pv.solarman = store.getters.getSolarmanProduction();
    this.pv.solarman.totalProduction =  Math.round((store.getters.getSolarmanProduction().totalProduction * 100))/100;
    this.pv.solarman.totalUsedPower =  Math.round((store.getters.getSolarmanProduction().totalUsedPower * 100))/100;
    //Huawei
    this.pv.huawei = store.getters.getHuaweiProduction();
    this.pv.huawei.totalProduction =  Math.round((store.getters.getHuaweiProduction().totalProduction * 100))/100;
    this.pv.huawei.totalUsedPower =  Math.round((store.getters.getHuaweiProduction().totalUsedPower * 100))/100;
    //Sungrow
    this.pv.sungrow = store.getters.getSungrowProduction();
    this.pv.sungrow.totalProduction =  Math.round((store.getters.getSungrowProduction().totalProduction * 100))/100;
    this.isLoaded = true;
    setInterval(() => this.getVensysProduction(null), 300000);
    setInterval(() => this.getSungrowProduction(null), 300000);
    setInterval(() => this.getHuaweiProduction(null), 300000);
    setInterval(() => this.getSolarmanProduction(null), 300000);
  }
})
export default class HomeAdmin extends Vue {}
</script>

<style lang="scss" scoped>
.contener 
{
  width: 90%;
  margin: auto;
}
</style>
