export function isTokenValid(token: string | null): boolean {
    if (!token) {
      return false
    }
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]))
      return decodedToken.exp > Date.now() / 1000
    } catch (error) {
      return false
    }
  }

export function getUserRole(token: string | null): string[] | null {
  if (!token) {
    return null;
  }
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const decodedPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const payload = JSON.parse(decodedPayload);
    return payload.roles || null; // Assuming the roles are stored under 'roles'
  } catch (error) {
    return null;
  }
}
  
  