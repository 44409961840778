<template>
   <div class="login">
    <CContainer v-if="!isLoading">
      <CRow class="justify-content-center align-items-center">
        <CCol md="8" lg="6" xl="5">
          <CForm @submit="submitForm" class="login-form">
            <h1>
              <b>Zaloguj się</b>
            </h1>
            <CInputGroup class="mb-3">
              <CInputGroupText>@</CInputGroupText>
              <CFormInput
                v-model="email"
                type="email"
                placeholder="stacja@watis.com.pl"
                autocomplete="email"
              />
            </CInputGroup>
            <CInputGroup class="mb-4">
              <CFormInput
                v-model="password"
                type="password"
                placeholder="********"
                autocomplete="password"
              />
            </CInputGroup>
            <CButton type="submit" color="success" class="w-100">
              Login
            </CButton>
          </CForm>
        </CCol>
      </CRow>
    </CContainer>
    <div v-else class="spinner-container">
      <CSpinner color="success" class="loading-spinner"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form {
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  }
}
.loading-spinner {
  height: 40vh;
  width: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { getUserRole }  from '@/utils/auth';
import { CSpinner, CForm, CFormInput, CButton, CRow, CCol, CContainer, CInputGroup, CInputGroupText } from '@coreui/vue';

@Options({
  components: {
    CSpinner, CForm, CFormInput, CButton, CRow, CCol, CContainer, CInputGroup, CInputGroupText
  },
  data() {
    return {
        email: '',
        password: '',
        isLoading: false,
        showPopup: false
    }
  }, 
  methods: {
    async submitForm(e: Event)
    {
        e.preventDefault()
        this.isLoading = true
        const body = {
        'username': this.email,
        'password': this.password
        }

        let data = await store.dispatch({
          'type': 'postRequest',
          'link': 'login_check',
          'body': body
        })
        if(data.code == 401)
        {
          alert('Invalid username or password')
          this.isLoading = false;
          return;
        }

        localStorage.setItem('token', data.token)
        const roles = getUserRole(data.token);
        if (roles && roles.length > 0) {
          localStorage.setItem('role', roles[0]); // Stores the first role in local storage
        }
        this.isLoading = false;
        return this.$router.push('/')
    }
  }
})
export default class LoginView extends Vue {}
</script>
