<template>
<div class="pv-solarman">
    <div class="pv-solarman card-container">
      <CCard v-for="installation in installations" v-bind:key="installation.installation">
      <CCardImage orientation="top" src="https://img1.igen-tech.com/default/7a6488eb2e43452181cf74253e81ae6b1663179703125.jpg" />
      <CCardBody>
        <CCardTitle>{{ installation.installation }}</CCardTitle>
        <div v-if="installation.success">
          <CCardText>Z sieci: {{ installation.powerFromGrid }}</CCardText>
          <CCardText>Z PV: {{ installation.powerFromPV }}</CCardText>
          <CCardText>Do sieci: {{ installation.powerToTheGrid }}</CCardText>
          <CCardText>Całkowite zuzycie: {{ installation.totalUsedPower }}</CCardText>
        </div>
        <div v-else>
            <CCardText>
              <span class="error-msg">
                {{ installation.msg }}
              </span>
            </CCardText>
        </div>
      </CCardBody>
      <CCardFooter>
            <CButton >
                <router-link :to="'/admin/pv/' + installation.id">Zobacz więcej</router-link>
            </CButton>
        </CCardFooter>
    </CCard>
    </div>
</div>
</template>


<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CCard, CCardImage, CCardBody, CCardTitle, CCardText, CButton, CCardFooter } from '@coreui/vue';

@Options({
  components: {
    CCard, CCardImage, CCardBody, CCardTitle, CCardText, CButton, CCardFooter
  },
  data() {
    return {
        pv: {
            pvHuawei: {},
            solarman: {
            totalProduction: 0,
            pvSolarman: [
                {
                success: false,
                id: 0,
                installation: '',
                msg: '',
                powerFromGrid: 0,
                powerFromPV: 0,
                powerToTheGrid: 0,
                totalUsedPower: 0
                }
            ]
            }
        }
    }
  },
  methods: {
    isObjectLoaded(obj: Object) {
      return obj && Object.keys(obj).length > 0;
    }
  },
  props: ['installations'],
  async created() {
  }
})
export default class SolarmanView extends Vue {}
</script>

<style scoped>
/* For ultra-wide displays */
@media (min-width: 1600px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr); /* 8 cards in a row */
    gap: 16px; /* Adjust the gap between cards */
  }
}

/* For large displays */
@media (min-width: 1200px) and (max-width: 1599px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 cards in a row */
    gap: 16px; /* Adjust the gap between cards */
  }
}

/* For medium displays */
@media (min-width: 768px) and (max-width: 1199px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 cards in a row */
    gap: 16px; /* Adjust the gap between cards */
  }
}

/* For small displays (e.g. mobile phones) */
@media (max-width: 767px) {
  .card-container {
    display: block; /* Cards stack vertically on small screens */
  }
}

.error-msg {
  color: red;
}
</style>

