<template>
  <div class="home">
    <home-admin v-if="isAdmin" />
    <home-user v-if="!isAdmin" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HomeAdmin from '@/components/admin/HomeView.vue'; // @ is an alias to /src
import HomeUser from '@/components/user/HomeView.vue'; // @ is an alias to /src

@Options({
  data() {
    return {
      isAdmin: false
    }
  },
  components: {
    HomeAdmin,
    HomeUser
  },
  created()
  {
    if(localStorage.getItem('role') == 'ROLE_ADMIN')
    {
      this.isAdmin = true
    }
  }
})
export default class HomeView extends Vue {}
</script>
