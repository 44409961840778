export function getAdjustedDate(input: string, baseDate: string | null) {
  let today = new Date();
  if (baseDate != null) {
    today = new Date(baseDate);
  }

  const match = /([+-]?\d+)\s*(day|days|min|mins)/i.exec(input);

  if (match) {
    const amount = parseInt(match[1], 10);
    const unit = match[2].toLowerCase();

    if (unit === 'day' || unit === 'days') {
      today.setDate(today.getDate() + amount);
    } else if (unit === 'min' || unit === 'mins') {
      today.setMinutes(today.getMinutes() + amount);
    }
  }

  return today;
}

export function getIntervalBetweenDates(startDateStr: string, endDateStr: string) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const dates = [];
  const timeDifference = endDate.getTime() - startDate.getTime();
  const numberOfDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  for (let i = 0; i <= numberOfDays; i++) {
    const currentDate = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
    dates.push(currentDate.toISOString().substring(0, 10)); // Format as 'YYYY-MM-DD' and add to the array
  }
  return dates;
}