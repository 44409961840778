import { createStore } from 'vuex';
import { getAdjustedDate, getIntervalBetweenDates } from '@/utils/date';
import router from '@/router';

export default createStore({
  state: {
    apiUrl: process.env.API_URL,
    token: null,
    vensysTurbines: {
      turbines: null,
      totalProduction: 0
    },
    pv: {
      pvHuawei:  {
        pvInstallations: null,
        totalProduction: 0,
        totalUsedPower: 0,
      },
      pvSolarman: {
        pvInstallations: null,
        totalProduction: 0,
        totalUsedPower: 0,
      },
      pvSungrow: {
        totalProduction: 0,
        pvInstallations: null
      }
    }
  },
  getters: {
  getDate: (state) => (adjustment: string|null, dateString: string|null) => 
  {
    let dt;
    if (dateString == null && adjustment == null) {
      dt = new Date();
    } else
    {
      dt = getAdjustedDate((adjustment ? ` ${adjustment}` : ''), dateString);
    }

    let year = dt.getFullYear();
    let month = (dt.getMonth() + 1).toString().padStart(2, "0");
    let day = dt.getDate().toString().padStart(2, "0");
    let hours = dt.getHours().toString().padStart(2, "0");
    let minutes = dt.getMinutes().toString().padStart(2, "0");
    let seconds = dt.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  },
  getIntervalBetweenDates: (state) => (startDateStr: string, endDateStr: string) => 
  {
    return getIntervalBetweenDates(startDateStr,endDateStr);
  },
  getVensysTurbinesProduction: (state) => () =>
  {
    return state.vensysTurbines;
  },
  getHuaweiProduction: (state) => () =>
  {
    return state.pv.pvHuawei;
  },
  getSolarmanProduction: (state) => () =>
  {
    return state.pv.pvSolarman;
  },
  getSungrowProduction: (state) => () =>
  {
    return state.pv.pvSungrow;
  }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    clearToken(state) {
      state.token = null
    },
    setVensysTurbinesProduction(state, vensysTurbines) {
      state.vensysTurbines.turbines = vensysTurbines.data;
      state.vensysTurbines.totalProduction = vensysTurbines.totalProduction;
    },
    setHuaweiProduction(state, pvProduction) {
      state.pv.pvHuawei.pvInstallations = pvProduction.data;
      state.pv.pvHuawei.totalProduction = pvProduction.totalProduction
      state.pv.pvHuawei.totalUsedPower = pvProduction.totalUsedPower
    },
    setSolarmanProduction(state, pvProduction) {
      state.pv.pvSolarman.pvInstallations = pvProduction.data;
      state.pv.pvSolarman.totalProduction = pvProduction.totalProduction
      state.pv.pvSolarman.totalUsedPower = pvProduction.totalUsedPower
    },
    setSungrowProduction(state, pvProduction) {
      state.pv.pvSungrow.pvInstallations = pvProduction.data;
      state.pv.pvSungrow.totalProduction = pvProduction.totalProduction
    },
  },
  actions: { 
    logout({ commit }) {
      // Clear the token from the store
      localStorage.clear()
      commit('clearToken')
      router.push('/login')
    },
    async postRequest({ commit, state }, { link, body }) {
      let headers = {};
      if(link != 'login_check')
      {
        headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }else
      {
        headers = {
          'Content-Type': 'application/json',
        }
      }
      
      return await fetch(`${state.apiUrl}${link}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      })
      .then(response => response.json())
      .catch(error => {
          alert('Coudn\'t fetch data. Try again')
        });

    },
    async getRequest({state}, {link})
    {
      return await fetch(`${state.apiUrl}${link}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(response => response.json())
      .catch(error => {
        console.log(error)
          alert('Coudn\'t fetch data. Try again')
      });
    },
  },
  modules: {
  },
});
