<template>
    <h2>
        Historyczna produkcja dla <span>{{ pvInstallation.installation }}</span>
    </h2>
    <hr>
    <date-picker-modal-component @pickedDates="fetchFromPickedDate"/>
    <hr>
    <h4>Od {{ pvInstallation.startDate }} Do: {{ pvInstallation.endDate }}</h4>
    <div class="historic-production-table" v-show="isLoaded">
        <CTable striped :columns="columns" :items="pvInstallation.data" />
    </div>
    <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { BodyParams } from '@/interfaces/body';
import { CSpinner, CTable } from '@coreui/vue';
import DatePickerModalComponent from '@/components/DatePickerModalComponent.vue';
import store from '@/store';

@Options({
    components: {
        DatePickerModalComponent, CSpinner, CTable
    },
    data() {
        return {
            isLoaded: false,
            columns: [
                {
                    key: 'date',
                    label: 'Data',
                    _props: { scope: 'col' },
                },
                {
                    key: 'powerFromPV',
                    label: 'Produkcja PV',
                    _props: { scope: 'col' },
                },
                {
                    key: 'powerToTheGrid',
                    label: 'Produkcja z PV do sieci',
                    _props: { scope: 'col' },
                },
                {
                    key: 'powerFromGrid',
                    label: 'Z sieci',
                    _props: { scope: 'col' },
                },
                {
                    key: 'totalUsedPower',
                    label: 'Zużyta energia',
                    _props: { scope: 'col' },
                },
            ],
            items: [],
            pvInstallation: {
                id: this.$route.params.id,
                installation: '',
                data: [
                {
                    date: '',
                    powerFromGrid: 0,
                    powerFromPV: 0,
                    powerToTheGrid: 0,
                    totalUsedPower: 0,
                }
                ],
                noErrors: true,
                startDate: '',
                endDate: ''
            },
            msg: ''
        }
    },
    methods: {
        async getPVHistoricProduction(dates: BodyParams)
        {            
            let body: BodyParams = {
                startDate: dates.startDate,
                endDate:  dates.endDate
            };

            try
            {
                let data = await store.dispatch({
                    'type': 'postRequest',
                    'link': 'production/pv/'+this.pvInstallation.id+'/historic',
                    'body': body
                })

                if(!data.success)
                {
                    alert(data.msg)
                    return;
                }

                const res = await data;
                this.pvInstallation = res;
                this.isLoaded = true;
            }catch(e: any)
            {
                this.msg = 'Nie mozna pobrac danych. Prawdopodobnie nie masz dostępu do tych zasobów'
                this.pvInstallation.noErrors = false;
                return;
            }
        },
        async fetchFromPickedDate(pickedDates: BodyParams)
        {
            this.isLoaded = false;
            await this.getPVHistoricProduction(pickedDates)
            this.isLoaded = true;
        }
    },
    async created() {
        this.isLoaded = false;
        await this.getPVHistoricProduction({
            startDate: store.getters.getDate('-7days', null).substring(0,10),
            endDate: store.getters.getDate().substring(0,10)
        })
        this.isLoaded = true;
    }
})
export default class PVHistoricProductionComponent extends Vue {}
</script>

<style scoped>
.historic-production-table {
    width: 90%;
    margin: auto;
}
</style>