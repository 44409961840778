<template>
    Statelista dla <span>WEA{{ $route.params.id }}</span>
    <hr>
    <date-picker-modal-component @pickedDates="fetchFromPickedDate"/>
    <hr>
    <div class="statelist-error-table" v-show="isLoaded">
        <CTable striped :columns="columns" :items="turbine.errors" v-if="!turbine.noErrors"/>
        <p v-else>{{ msg }}</p>
    </div>
    <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { BodyParams } from '@/interfaces/body';
import { CSpinner, CTable } from '@coreui/vue';
import DatePickerModalComponent from '@/components/DatePickerModalComponent.vue';
import store from '@/store';

@Options({
    components: {
        DatePickerModalComponent, CSpinner, CTable
    },
    data() {
        return {
            isLoaded: false,
            columns: [
                {
                    key: 'type',
                    _props: { scope: 'col' },
                },
                {
                    key: 'timestamp',
                    _props: { scope: 'col' },
                },
                {
                    key: 'twinCat',
                    _props: { scope: 'col' },
                },
                {
                    key: 'code',
                    _props: { scope: 'col' },
                },
                {
                    key: 'description',
                    _props: { scope: 'col' },
                },
                {
                    key: 'status',
                    _props: { scope: 'col' },
                },
            ],
            items: [],
            turbine: {
                id: this.$route.params.id,
                name: '',
                errors: [
                    {
                        type: '',
                        timestamp: '',
                        twinCat: 2,
                        code: 0,
                        description: '',
                        status: ''
                    }
                ],
                noErrors: true,
                startDate: '',
                endDate: ''
            },
            msg: ''
        }
    },
    methods: {
        async getVensysStatelist(dates: BodyParams|null)
        {
            let body: BodyParams = {
                'startDate': '',
                'endDate': ''
            };
            if(dates != null)
            {
                body = {
                'startDate': dates.startDate,
                'endDate': dates.endDate
                }
            }else
            {
                body = {
                'startDate':  store.getters.getDate().substring(0,8)+'01',
                'endDate':  store.getters.getDate().substring(0,8)+'31'
                }
            }
            let data = await store.dispatch({
                'type': 'postRequest',
                'link': 'alerts/vensys/statelist/'+this.turbine.id,
                'body': body
            })
            if(!data.success)
            {
                alert('Problem while retriving data from API')
                this.msg = "Podano błędne ID turbiny"
                return;
            }
            const res = await data.data
            if(res.errors.length == 0)
            {
                this.turbine.noErrors = true
                this.msg = `Brak błędów od: ${body.startDate} do ${body.endDate} dla WEA${this.turbine.id}`
            }else if(typeof res.errors == 'object')
            {
                this.turbine.noErrors = true
                this.msg = `Brak błędów od: ${body.startDate}do ${body.endDate} dla WEA${this.turbine.id}`
                if(Array.isArray(res.errors))
                {
                    this.turbine.errors = res.errors;
                    this.turbine.noErrors = false;
                }
            }
        },
        async fetchFromPickedDate(pickedDates: BodyParams)
        {
            this.isLoaded = false;
            if(pickedDates.startDate == pickedDates.endDate)
            {
                this.today = `${pickedDates.startDate}`
            }else
            {
                this.today = `Od: ${pickedDates.startDate} do ${pickedDates.endDate}`
            }

            const dates = {...pickedDates};
            await this.getVensysStatelist(dates)
            this.isLoaded = true;
        }
    },
    async created() {
        this.isLoaded = false;
        await this.getVensysStatelist(null)
        this.isLoaded = true;
    }
})
export default class VensysStatelistComponent extends Vue {}
</script>

<style scoped>
.statelist-error-table {
    width: 90%;
    margin: auto;
}
</style>