<template>
    <CNav class="justify-content-center" variant="tabs" v-show="showNav">
      <CNavItem>
        <CNavLink :active="isActive('/')" class="nav-link">
          <router-link to="/">Home</router-link>
        </CNavLink>
      </CNavItem>
      <CNavItem v-show="isAdmin">
        <CNavLink :active="isActive('/billing')" class="nav-link">
          <router-link to="/billing">Rozliczenia</router-link>
        </CNavLink>
      </CNavItem>
      <CNavItem v-show="isAdmin">
        <CNavLink :active="isActive('/settings/admin')" class="nav-link">
          <router-link to="/settings/admin">Admin Settings</router-link>
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink :active="isActive('/login')" class="nav-link">
          <router-link to="/login" @click="logout">Logout</router-link>
        </CNavLink>
      </CNavItem>
    </CNav>
</template>
  
<style scoped lang="scss">
.nav {
  margin-top: 10px;
}
.nav-link {
  color: black;
  text-decoration: none;
  a {
    color: black;
    text-decoration: none;

    :active {
        color: #32a834;
        text-decoration: underline;
    }
  }
}
</style>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CNav, CNavItem, CNavLink } from '@coreui/vue';
import store from '@/store';

@Options({
data() {
    return {};
},
components: {
    CNav,
    CNavItem,
    CNavLink,
},
computed: {
    showNav() {
    if (this.$route.path == '/login') {
        return false;
    }
    return true;
    },
    isAdmin() {
    if (this.$route.path != '/login') {
      if (localStorage.getItem('role') != 'ROLE_ADMIN') {
            return false;
      }
      return true;
    }
    return false;
    },
},
methods: {
    isActive(path: string) {
    return this.$route.path === path;
    },
    logout() {
    store.dispatch('logout');
    },
},
})
export default class NavComponent extends Vue {}
</script>
