<template>
    <div class="vensys">
        <div class="vensys card-container">
        <CCard v-for="pvFarm in pvFarms" v-bind:key="pvFarm.name">
            <CCardImage orientation="top" src="https://images.pexels.com/photos/15751123/pexels-photo-15751123/free-photo-of-clouds-over-solar-panels.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
            <CCardBody>
                <CCardTitle>{{ pvFarm.installation }}</CCardTitle>
                <CCardText><b>Produkcja: {{ pvFarm.powerFromPV }} {{ pvFarm.unit }}</b></CCardText>
            </CCardBody>
            <CCardFooter>
                <CButton >
                    <router-link :to="'/admin/pv/' + pvFarm.id">Zobacz więcej</router-link>
                </CButton>
            </CCardFooter>
        </CCard>
        </div>
    </div>
</template>
    
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CCard, CCardImage, CCardBody, CCardTitle, CCardText, CButton, CCardFooter } from '@coreui/vue';

@Options({
    components: {
    CCard, CCardImage, CCardBody, CCardTitle, CCardText, CButton, CCardFooter
    },
    methods: {
    isObjectLoaded(obj: Object) {
        return obj && Object.keys(obj).length > 0;
    }
    },
    props: ['pvFarms'],
    async created() {
    }
})
export default class PVFarmView extends Vue {}
</script>

<style scoped>
/* For ultra-wide displays */
@media (min-width: 1600px) {
    .card-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr); /* 8 cards in a row */
    gap: 16px; /* Adjust the gap between cards */
    }
}

/* For large displays */
@media (min-width: 1200px) and (max-width: 1599px) {
    .card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 cards in a row */
    gap: 16px; /* Adjust the gap between cards */
    }
}

/* For medium displays */
@media (min-width: 768px) and (max-width: 1199px) {
    .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 cards in a row */
    gap: 16px; /* Adjust the gap between cards */
    }
}

/* For small displays (e.g. mobile phones) */
@media (max-width: 767px) {
    .card-container {
    display: block; /* Cards stack vertically on small screens */
    }
}
</style>