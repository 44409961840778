<template>
    <scada-nav-component :isPV="false"/>
    <router-view />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'; 
import ScadaNavComponent from '@/components/nav/SCADANavComponent.vue';

@Options({
    components: {
        ScadaNavComponent
    },
})
export default class TurbineDetailView extends Vue {}
</script>
