<template>
  <Nav />
  <router-view/>
  <footer>
    <hr>
    Watis &copy; 2024
  </footer>
</template>

<style scoped>
footer
{
  margin-top: 2rem;
  margin-bottom: 1rem;
}
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Nav from '@/components/nav/NavComponent.vue';
import '@coreui/coreui/dist/css/coreui.min.css';
import store from '@/store';

@Options({
  components: {
    Nav
  },
  created () {
    if(localStorage.getItem('token') == null)
    {
      this.$router.push('/login')
    }
  }
  
})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.spinner {
    text-align: center;
    width: 200px;
    height: 200px;
  }
</style>
