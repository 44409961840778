<template>
    Produkcja dla <span>{{ pvInstallation.installation }}</span>
    <hr>
    <div class="production-data" v-show="isLoaded">
        <div v-if="pvInstallation.noErrors" class="production-data-flow-chart">
            <pv-flow-chart :data="pvInstallation.data" v-if="isLoaded" v-show="isLoaded" />
        </div>
        <hr>
        <p v-if="pvInstallation.noErrors">
            Odświeżono {{ pvInstallation.lastUpdated }} minuty temu
        </p>
        <p v-else>{{ msg }}</p>
    </div>
    <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CSpinner, CTable,  CProgress, CProgressBar } from '@coreui/vue';
import store from '@/store';
import PvFlowChart from '@/components/pv/components/PVFlowChart.vue';

@Options({
    components: {
        CSpinner, CTable, CProgress, CProgressBar, PvFlowChart
    },
    data() {
        return {
            isLoaded: false,
            pvInstallation: {
                id: this.$route.params.id,
                installation: '',
                lastUpdateTime: '',
                lastUpdated: 0,
                data: {
                    powerFromPV: 0,
                    powerFromGrid: 0,
                    powerToTheGrid: 0,
                    currentPowerUsage: 0
                },
                noErrors: true
            },
            msg: ''
        }
    },
    methods: {
        async getCurrentProduction()
        {
            try
            {
                let data = await store.dispatch({
                'type': 'getRequest',
                'link': 'production/pv/'+this.pvInstallation.id+'/current'
                })   
                if(! await data.success)
                {
                    this.msg = data.msg
                    this.pvInstallation.noErrors = false;
                    return;
                }
                this.pvInstallation = await data
                this.getMinutesFromLastUpdatedTime()
                this.pvInstallation.noErrors = true;
            }catch(e: any)
            {
                this.msg = 'Nie mozna pobrac danych. Prawdopodobnie nie masz dostępu do tych zasobów'
                this.pvInstallation.noErrors = false;
                return;
            }
        },
        getMinutesFromLastUpdatedTime()
        {
            let now = new Date().getTime();
            let updateTime = new Date(this.pvInstallation.lastUpdateTime).getTime();
            this.pvInstallation.lastUpdated = new Date(now - updateTime).getMinutes()
        }
    },
    async created() {
        this.isLoaded = false;
        await this.getCurrentProduction()
        this.isLoaded = true;
        setInterval(() => this.getCurrentProduction(), 60000);
    }
})
export default class PVProductionComponent extends Vue {}
</script>

<style scoped lang="scss">
.production-data {
    width: 90%;
    margin: auto;

    &-flow-chart {
        margin-top: -30px;
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        width: 100%; /* Set the width to 100% to center the content horizontally */
        text-align: center;
    }
}
</style>