import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { isTokenValid }  from '@/utils/auth';
import HomeView from '../views/HomeView.vue';
import BillingView from '../views/BillingView.vue';
import LoginView from '../views/LoginView.vue';
import SettingsAdmin from '../views/settings/AdminSettingsView.vue';
import TurbineDetailView from '@/views/admin/TurbineDetailView.vue';
import PVDetailView from '@/views/admin/PVDetailView.vue';
import VensysProductionComponent from '@/components/turbines/VensysProductionComponent.vue'
import VensysHistoricProductionComponent from '@/components/turbines/VensysHistoricProductionComponent.vue'
import VensysStatelistComponent from '@/components/turbines/VensysStatelistComponent.vue'
import VensysInfoComponent from '@/components/turbines/VensysInfoComponent.vue'
import PVProductionComponent from '@/components/pv/components/PVProductionComponent.vue';
import PVHistoricProductionComponent from '@/components/pv/components/PVHistoricProductionComponent.vue';
import PVStatelistComponent from '@/components/pv/components/PVStatelistComponent.vue';
import PVInfoComponent from '@/components/pv/components/PVInfoComponent.vue';
import CommandList from '@/components/settings/command/CommandListComponent.vue';
import CommandSetup from '@/components/settings/command/CommandSetupComponent.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/billing',
    name: 'billing',
    component: BillingView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/settings/admin',
    name: 'adminSettings',
    component: SettingsAdmin,
    children: [
      {
        path: 'commands',
        name: 'commandList',
        component: CommandList,
      },
      {
        path: 'commands/:id/setup',
        name: 'commandSetup',
        component: CommandSetup
      }
    ]
  },
  {
    path: '/admin/turbine/:id',
    name: 'adminTurbineView',
    component: TurbineDetailView,
    children: [
      {
        path: '',
        name: 'turbineProduction',
        component: VensysProductionComponent,
      },
      {
        path: 'production/historic',
        name: 'turbineHistoricProduction',
        component: VensysHistoricProductionComponent,
      },
      {
        path: 'statelist',
        name: 'turbineStatelist',
        component: VensysStatelistComponent,
      },
      {
        path: 'info',
        name: 'turbineInfo',
        component: VensysInfoComponent,
      },
    ],
  },
  {
    path: '/admin/pv/:id',
    name: 'adminPVView',
    component: PVDetailView,
    children: [
      {
        path: '',
        name: 'pvProduction',
        component: PVProductionComponent,
      },
      {
        path: 'production/historic',
        name: 'pvHistoricProduction',
        component: PVHistoricProductionComponent,
      },
      {
        path: 'statelist',
        name: 'pvStatelist',
        component: PVStatelistComponent,
      },
      {
        path: 'info',
        name: 'pvInfo',
        component: PVInfoComponent,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token')

    if (!isTokenValid(token)) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;
