import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_admin = _resolveComponent("home-admin")!
  const _component_home_user = _resolveComponent("home-user")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_home_admin, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_home_user, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}