import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-507e4c96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vensys" }
const _hoisted_2 = { class: "vensys card-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CCardImage = _resolveComponent("CCardImage")!
  const _component_CCardTitle = _resolveComponent("CCardTitle")!
  const _component_CCardText = _resolveComponent("CCardText")!
  const _component_CCardBody = _resolveComponent("CCardBody")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CButton = _resolveComponent("CButton")!
  const _component_CCardFooter = _resolveComponent("CCardFooter")!
  const _component_CCard = _resolveComponent("CCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.turbines, (turbine) => {
        return (_openBlock(), _createBlock(_component_CCard, {
          key: turbine.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CCardImage, {
              orientation: "top",
              src: "https://images.pexels.com/photos/414837/pexels-photo-414837.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            }),
            _createVNode(_component_CCardBody, null, {
              default: _withCtx(() => [
                _createVNode(_component_CCardTitle, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(turbine.name), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_CCardText, null, {
                  default: _withCtx(() => [
                    _createElementVNode("b", null, "Produkcja: " + _toDisplayString(turbine.production) + " kWh", 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_CCardFooter, null, {
              default: _withCtx(() => [
                _createVNode(_component_CButton, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: '/admin/turbine/' + turbine.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Zobacz więcej")
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])
  ]))
}