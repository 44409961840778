<template>
    <CButton color="success" @click="() => { showDatePicker = true }">Wybierz datę</CButton>
    <CModal alignment="center" :visible="showDatePicker" @close="() => { showDatePicker = false }">
        <CModalHeader>
            <CModalTitle>Wybierz datę: </CModalTitle>
        </CModalHeader>
        <CModalBody>
            <form method="POST">
                <CFormLabel for="inputEmail4">Od: </CFormLabel>
                <input type="date" v-model="pickedDates.startDate"/>
                <br>
                <CFormLabel for="inputEmail4">Do: </CFormLabel>
                <input type="date" v-model="pickedDates.endDate"/>
            </form>
            <CButton color="secondary" @click="resetDates">RESET</CButton>
        </CModalBody>
        <CModalFooter>
            <CButton color="secondary" @click="() => { showDatePicker = false }">
                Close
            </CButton>
            <CButton color="success" @click="setPickedDate">Save changes</CButton>
        </CModalFooter>
    </CModal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CButton, CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle, CFormLabel } from '@coreui/vue';
import store from '@/store';

@Options({
    components: {
        CButton, CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle, CFormLabel,
    },
    data() {
        return {
            showDatePicker: false,
            today: store.getters.getDate().substring(0,10),
            pickedDates: {
                startDate: store.getters.getDate('-1day').substring(0,10),
                endDate: store.getters.getDate().substring(0,10),
            }
        }
    },
    methods: {
        setPickedDate(e: Event)
        {
            e.preventDefault()
            if(this.pickedDates.endDate > this.today || this.pickedDates.startDate > this.today)
            {
                alert("Podana data nie może być większa od dzisiejeszej")
                return;
            }

            this.$emit('pickedDates', this.pickedDates);
            this.pickedDates.startDate = store.getters.getDate('-1day').substring(0,10);
            this.pickedDates.endDate = this.today;
            this.showDatePicker = false;
        },
        resetDates()
        {
            this.pickedDates.startDate = this.today;
            this.pickedDates.endDate = this.today;
            this.$emit('pickedDates', this.pickedDates);
            this.showDatePicker = false;
        }
    },
    emits: ['pickedDates']
})
export default class DatePickerModalComponent extends Vue {}
</script>