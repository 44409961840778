<template>
  <div class="gutter-row ant-col ant-col-15 box-sizing-border-box display-block" style="padding-left: 4px; padding-right: 4px;">
        <div class="gutter-box height2 main-bd">
            <div class="h100pct posR normal-flowchart solarman-flowchart">
              <div class="content pd20x">
                  <div class="default-flow-style">
                      <div class="h100pct tableSys">
                          <div class="vaT">
                              <div class="ta-l">
                                  <div class="dpIB" style="margin-top: 25px;">
                                      <img src="@/assets/solarpanel.png" class="flow-icon-fd2">
                                  </div>
                                  <span class="dpIB posR" style="left: 10px; top: 20px;">
                                      <div><span>Production</span></div>
                                      <span class="font-w fs16x">{{ data.powerFromPV }}kW</span>
                                  </span>
                              </div>
                              <div class="pl40x">
                                  <span class="LDash active-line">
                                      <svg version="1.1" viewBox="0 0 61.5 137" style="width: 61.5px; height: 137px;">
                                          <polyline points="12,12 12,125 49.5,125" stroke-width="4" class="polyLine" style="fill: none;"></polyline>
                                          <circle cx="12" cy="12" r="6" stroke-width="0"></circle>
                                          <circle cx="-20" cy="-20" r="6" stroke-width="0" fill="#4C87FF">
                                              <animateMotion path="M32,32 L32,145 L69.5,145 " begin="0s" dur="3s" repeatCount="indefinite"></animateMotion>
                                          </circle>
                                          <polygon points="49.5,125 45.5,133 61.5,125 45.5,117"></polygon>
                                          <circle cx="49.5" cy="125" r="1" stroke-width="0"></circle>
                                      </svg>
                                  </span>
                              </div>
                          </div>
                          <div class="no-wrap w80x vaT">
                              <div class="dpIB" style="margin-top: 203px;">
                                  <img src="@/assets/inverter.png" class="flow-icon-cjq2">
                              </div>
                              <div>
                                  <span class="LDash active-line">
                                      <svg version="1.1" viewBox="0 0 80 97" style="width: 80px; height: 97px;">
                                          <polyline points="40,12 40,85" stroke-width="4" class="polyLine" style="fill: none;"></polyline>
                                          <circle cx="40" cy="12" r="6" stroke-width="0"></circle>
                                          <circle cx="-20" cy="-20" r="6" stroke-width="0" fill="#4C87FF">
                                              <animateMotion path="M60,32 L60,105 " begin="0s" dur="1.5s" repeatCount="indefinite"></animateMotion>
                                          </circle>
                                          <polygon points="40,85 32,81 40,97 48,81"></polygon>
                                          <circle cx="40" cy="85" r="1" stroke-width="0"></circle>
                                      </svg>
                                  </span>
                              </div>
                              <div class="ta-l">
                                  <img src="@/assets/building.png" class="flow-icon-yd2">
                                  <span class="dpIB posR" style="left: 10px; top: 20px;">
                                      <div><span>Consumption</span></div>
                                      <span class="font-w fs16x">{{ data.currentPowerUsage}}kW</span>
                                  </span>
                              </div>
                          </div>
                          <div class="vaT">
                              <div class="ta-r">
                                  <img src="@/assets/grid.png" class="flow-icon-dw2">
                                  <span class="dpIB posR" style="top: 20px;">
                                      <div><span>Grid</span></div>
                                      <span class="font-w fs16x">
                                        <div v-if="isFromGrid">
                                          {{ data.powerFromGrid}}kW
                                        </div>
                                        <div v-else>
                                          {{ data.powerToTheGrid}}kW
                                        </div>
                                      </span>
                                  </span>
                              </div>
                              <!-- This is for from grid-->
                              <div class="pl10x" v-if="isFromGrid">
                                  <span class="LDash mb05x active-line">
                                      <svg version="1.1" viewBox="0 0 61.5 137" style="width: 61.5px; height: 137px;">
                                          <polyline points="49.5,12 49.5,125 12,125" stroke-width="4" class="polyLine" style="fill: none;"></polyline>
                                          <circle cx="49.5" cy="12" r="6" stroke-width="0"></circle>
                                          <circle cx="-20" cy="-20" r="6" stroke-width="0" fill="#4C87FF">
                                              <animateMotion path="M69.5,32 L69.5,145 L32,145 " begin="0s" dur="3s" repeatCount="indefinite"></animateMotion>
                                          </circle>
                                          <polygon points="12,125 16,133 0,125 16,117"></polygon>
                                          <circle cx="12" cy="125" r="1" stroke-width="0"></circle>
                                      </svg>
                                  </span>
                              </div>
                              <!-- Only this part is different to the grid-->
                              <div class="pl10x" v-else>
                                  <span class="LDash mb05x active-line">
                                    <svg version="1.1" viewBox="0 0 61.5 137" style="width: 61.5px; height: 137px;">
                                          <polyline points="12,125 25,125 25,12" stroke-width="4" class="polyLine" style="fill: none;"></polyline>
                                          <circle cx="12" cy="125" r="6" stroke-width="0"></circle>
                                          <circle cx="-20" cy="-20" r="6" stroke-width="0" fill="#4C87FF">
                                              <animateMotion path="M32,145 L45,145 L45,32 " begin="0s" dur="3s" repeatCount="indefinite"></animateMotion>
                                          </circle>
                                          <polygon points="25,12 17,16 25,0 33,16"></polygon>
                                          <circle cx="25" cy="12" r="1" stroke-width="0"></circle>
                                      </svg>
                                  </span>
                              </div>
                              <!-- end here-->
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    },
    data() {
      return {
        isFromGrid: true,
        isLoaded: false
      }
    },
    props: ['data'],
    methods: {
      async isFromGridCheck()
      {
        if(parseFloat(await this.data.powerToTheGrid) > 0)
        {
          this.isFromGrid = false
        }else
        {
          this.isFromGrid = true
        }
      },
    },
    async created() {
      this.isFromGridCheck()
    },
})
export default class PVFlowChartComponent extends Vue {}
</script>

<style scoped>
*,:after,:before{-webkit-box-sizing:border-box;box-sizing:border-box;}
img{vertical-align:middle;border-style:none;}
svg:not(:root){overflow:hidden;}
::selection{color:#fff;background:#1890ff;}
.ant-row{display:block;}
.ant-row{position:relative;height:auto;margin-right:0;margin-left:0;zoom:1;-webkit-box-sizing:border-box;box-sizing:border-box;}
.ant-row:after,.ant-row:before{display:table;content:"";}
.ant-row:after{clear:both;}
.ant-col{position:relative;min-height:1px;}
.ant-col-12,.ant-col-15{position:relative;padding-right:0;padding-left:0;}
.ant-col-12,.ant-col-15{-webkit-box-flex:0;-ms-flex:0 0 auto;flex:0 0 auto;float:left;}
.ant-col-15{display:block;-webkit-box-sizing:border-box;box-sizing:border-box;width:62.5%;}
.ant-col-12{display:block;-webkit-box-sizing:border-box;box-sizing:border-box;width:50%;}
.ant-layout *{-webkit-box-sizing:border-box;box-sizing:border-box;}
*,:after,:before{-webkit-box-sizing:border-box;box-sizing:border-box;}
img{vertical-align:middle;border-style:none;}
svg:not(:root){overflow:hidden;}
::selection{color:#fff;background:#1890ff;}
.ant-row{position:relative;height:auto;margin-right:0;margin-left:0;zoom:1;display:block;-webkit-box-sizing:border-box;box-sizing:border-box;}
.ant-row:after,.ant-row:before{display:table;content:"";}
.ant-row:after{clear:both;}
.ant-col{position:relative;min-height:1px;}
.ant-col-12,.ant-col-15{position:relative;padding-right:0;padding-left:0;}
.ant-col-12,.ant-col-15{-webkit-box-flex:0;-ms-flex:0 0 auto;flex:0 0 auto;float:left;}
.ant-col-15{display:block;-webkit-box-sizing:border-box;box-sizing:border-box;width:62.5%;}
.ant-col-12{display:block;-webkit-box-sizing:border-box;box-sizing:border-box;width:50%;}
.ant-layout *{-webkit-box-sizing:border-box;box-sizing:border-box;}
.pd20x{padding:20px;}
.pl10x{padding-left:10px;}
.pl40x{padding-left:40px;}
.mb05x{margin-bottom:5px;}
.h100pct{height:100%;}
.w80x{width:80px;}
.font-w{font-weight:600;}
.fs16x{font-size:16px;}
.ta-r{text-align:right;}
.ta-l{text-align:left;}
.posR{position:relative;}
.dpIB{display:inline-block;}
.vaT{vertical-align:top;}
.no-wrap{white-space:nowrap;}
:focus{outline:none;}
.tableSys{width:100%;display:table;table-layout:fixed;}
.tableSys>*{display:table-cell;}
.active-line{fill:#afc3eb;stroke:#afc3eb;}
.flow-icon-fd2{width:95px;height:81px;display:inline-block;}
.flow-icon-dw2{width:52px;height:106px;display:inline-block;}
.flow-icon-cjq2{width:80px;height:68px;display:inline-block;}
.flow-icon-yd2{width:66px;height:78px;display:inline-block;}
img{width:100%;}
.default-flow-style{height:100%;}
.plant-data-body .solarman-style .gutter-box{background:#fff;border-radius:4px;text-align:left;padding:0 0 10px;}
.plant-data-body .solarman-style .height2{height:554px;}
.plant-data-body .solarman-style .head{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;padding:20px;}
.plant-data-body .solarman-style .head .title{line-height:24px;color:#272727;font-size:20px;font-weight:600;}
.plant-data-body .solarman-style .content{width:100%;height:calc(100% - 65px);padding:0 26px;}
img{border-style:none;}
::selection{color:#fff;background:#4c87ff;}
</style>
