<template>
    Produkcja dla <span>WEA{{ $route.params.id }}</span>
    <hr>
    <div class="production-data" v-show="isLoaded">
        <div v-if="turbine.noErrors">
            <CProgress class="mb-3">
                <CProgressBar color="success" variant="striped" animated :value="turbine.data.productionBar"/>
            </CProgress>
            Produkcja: {{ turbine.data.productionAvg }} kW
            <hr>
            Max Produkcja: {{ turbine.data.productionMax }} kW
            <hr>
            Średnia prędkość wiatru: {{ turbine.data.windSpeedAvg }} m/s
            <hr>
            Max prędkość wiatru: {{ turbine.data.windSpeedMax }} m/s
            <hr>
            Produkcja dzisiaj: {{ turbine.totalProduction }} kWh
        </div>
        <p v-else>{{ msg }}</p>
    </div>
    <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CSpinner, CTable,  CProgress, CProgressBar } from '@coreui/vue';
import store from '@/store';

@Options({
    components: {
        CSpinner, CTable, CProgress, CProgressBar
    },
    data() {
        return {
            isLoaded: false,
            turbine: {
                id: this.$route.params.id,
                name: '',
                totalProduction: 0,
                data: {
                    productionAvg: 0,
                    productionMax: 0,
                    windSpeedAvg: 0,
                    windSpeedMax: 0,
                    productionBar: 0,
                },
                noErrors: true
            },
            msg: ''
        }
    },
    methods: {
        async getVensysProduction()
        {
            let data = await store.dispatch({
                'type': 'getRequest',
                'link': 'production/vensys/'+this.turbine.id+'/current'
            })
            if(! await data.success)
            {
                this.msg = data.msg
                this.turbine.noErrors = false;
                return;
            }
            this.turbine = await data.data
            this.turbine.data.productionBar = (this.turbine.data.productionAvg / 1150 * 100)
            this.turbine.noErrors = true;
        },
    },
    async created() {
        this.isLoaded = false;
        await this.getVensysProduction()
        this.isLoaded = true;
        setInterval(() => this.getVensysProduction(), 60000);
    }
})
export default class VensysProductionComponent extends Vue {}
</script>

<style scoped>
.production-data {
    width: 90%;
    margin: auto;
}
</style>
