<template>
    Info dla <span>WEA{{ $route.params.id }}</span>
    <div class="turbine">
        <div class="turbine-info">
            Szczegóły techniczne
            <CContainer v-if="noErrors">
                <CRow :xs="{ cols: 1 }" :md="{ cols: 2}">
                    <CCol sm="auto" class="turbine-info" v-if="isLoaded">
                        <CRow>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Nr. seryjny</td>
                                        <td>{{ turbine.serialNo }}</td>
                                    </tr>
                                    <tr>
                                        <td>Park wiatrowy</td>
                                        <td>{{ turbine.windFarm.farmName }},{{ turbine.windFarm.country }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </CRow>
                        <CRow>
                            <CImage fluid src="https://images.pexels.com/photos/414837/pexels-photo-414837.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                        </CRow>
                    </CCol>
                    <CCol sm="auto" class="turbine-info-technical" >
                        <CRow v-if="isLoaded">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Model</td>
                                        <td>{{ turbine.technicalDetails.modelName }}</td>
                                    </tr>
                                    <tr>
                                        <td>Platforma</td>
                                        <td>{{ turbine.technicalDetails.platform }}</td>
                                    </tr>
                                    <tr>
                                        <td>Moc</td>
                                        <td>{{ turbine.technicalDetails.maxPower }}</td>
                                    </tr>
                                    <tr>
                                        <td>Wysokość</td>
                                        <td>{{ turbine.technicalDetails.hubHeight }}</td>
                                    </tr>
                                    <tr>
                                        <td>Rodzaj wieży</td>
                                        <td>{{ turbine.technicalDetails.towerType }}</td>
                                    </tr>
                                    <tr>
                                        <td>Średnica rotora</td>
                                        <td>{{ turbine.technicalDetails.rotorDiameter }}</td>
                                    </tr>
                                    <tr>
                                        <td>Typ łopaty</td>
                                        <td>{{ turbine.technicalDetails.rotorBladeType }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </CRow>
                        <CRow class="turbine-location">
                            Mapa:
                            <br>
                            <div ref="mapContainer" style="width: 300px; height: 200px;"></div>
                        </CRow>
                    </CCol>
                </CRow>
            </CContainer>
            <p v-else>{{ msg }}</p>
        </div>
    </div>
    <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CSpinner, CImage, CContainer, CRow, CCol } from '@coreui/vue';
import store from '@/store';
import L from 'leaflet';

@Options({
    components: {
        CSpinner, CImage, CContainer, CRow, CCol
    },
    data() {
        return {
            isLoaded: false,
            noErrors: true,
            turbine: {
                id: this.$route.params.id,
                name: '',
                serialNo: 0,
                technicalDetails: {
                    modelName: '',
                    maxPower: 0,
                    platform: '',
                    hubHeight: 0,
                    towerType: '',
                    rotorDiameter: 0,
                    rotorBladeType: ''
                },
                location: {
                    latitude: '',
                    longitude: ''
                },
                windFarm: {
                    country: '',
                    farmName: '',
                    shortName: ''
                }
            }
        }
    },
    methods: {
        async getVensysTurbineInfo()
        {
            let data = await store.dispatch({
                'type': 'getRequest',
                'link': 'info/vensys/'+this.turbine.id,
            })
            if(!data.success)
            {
                alert('Problem while retriving data from API')
                this.msg = "Podano błędne ID turbiny";
                this.noErrors = false;
                return;
            }
            const res = await data.turbine
            this.turbine = res;
            this.initializeMap();
        },
        initializeMap() {
            // Create the map instance and set the view using the location data
            this.map = L.map(this.$refs.mapContainer).setView([this.turbine.location.latitude, this.turbine.location.longitude], 14);

            // Add a tile layer from OpenStreetMap
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(this.map);

            // Add a marker at the turbine's location
            L.marker([this.turbine.location.latitude, this.turbine.location.longitude]).addTo(this.map);
        },
    },
    async created() {
        this.isLoaded = false;
        await this.getVensysTurbineInfo();
        this.isLoaded = true;
    }
})
export default class VensysInfoComponent extends Vue {}
</script>