<template>
  User Display
  <div class="pv">
    <div v-if="isObjectLoaded(production.pvProduction.pvHuawei)">
      <installations-view :installations="production.pvProduction.pvHuawei" />
    </div>
    <div v-if="isObjectLoaded(production.pvProduction.pvSolarman)">
      <installations-view :installations="production.pvProduction.pvSolarman" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { BodyParams } from '@/interfaces/body';
import InstallationsView from '@/components/pv/InstallationsView.vue';
import store from '@/store';

@Options({
  components: {
    InstallationsView
  },
  data() {
    return {
      dataLoaded: false,
      production: {
        date: '',
        totalProduction: 0,
        totalUsedEnergy: 0,
        vensysProduction: [],
        pvProduction: {
          pvHuawei: [],
          pvSolarman: [
              {
                  success: false,
                  installation: "",
                  powerFromGrid: 0,
                  powerFromPV: 0,
                  powerToTheGrid: 0,
                  totalUsedPower: 0
              }
          ]
        }
      }
    }
  },
  methods: {
    async getPVproduction(dates: BodyParams|null)
    {
      this.dataLoaded = false;
     
      let data = await store.dispatch({
        'type': 'getRequest',
        'link': 'production'
      })

      this.production = data
      this.dataLoaded = true
    },
    isObjectLoaded(array: Array<object>) {
      return array.length > 0;
    }
  },
  async created() {
    await this.getPVproduction(null)
  }
})
export default class HomeUser extends Vue {}
</script>
