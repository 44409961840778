import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CNavLink = _resolveComponent("CNavLink")!
  const _component_CNavItem = _resolveComponent("CNavItem")!
  const _component_CNav = _resolveComponent("CNav")!

  return (_openBlock(), _createBlock(_component_CNav, { class: "justify-content-center" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentNavLinks, (link, index) => {
        return (_openBlock(), _createBlock(_component_CNavItem, { key: index }, {
          default: _withCtx(() => [
            _createVNode(_component_CNavLink, {
              active: link.active
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: link.to
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(link.text), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 2
            }, 1032, ["active"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}