import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33a15f4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pv-solarman" }
const _hoisted_2 = { class: "pv-solarman card-container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "error-msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CCardImage = _resolveComponent("CCardImage")!
  const _component_CCardTitle = _resolveComponent("CCardTitle")!
  const _component_CCardText = _resolveComponent("CCardText")!
  const _component_CCardBody = _resolveComponent("CCardBody")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CButton = _resolveComponent("CButton")!
  const _component_CCardFooter = _resolveComponent("CCardFooter")!
  const _component_CCard = _resolveComponent("CCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.installations, (installation) => {
        return (_openBlock(), _createBlock(_component_CCard, {
          key: installation.installation
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CCardImage, {
              orientation: "top",
              src: "https://img1.igen-tech.com/default/7a6488eb2e43452181cf74253e81ae6b1663179703125.jpg"
            }),
            _createVNode(_component_CCardBody, null, {
              default: _withCtx(() => [
                _createVNode(_component_CCardTitle, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(installation.installation), 1)
                  ]),
                  _: 2
                }, 1024),
                (installation.success)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_CCardText, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Z sieci: " + _toDisplayString(installation.powerFromGrid), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_CCardText, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Z PV: " + _toDisplayString(installation.powerFromPV), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_CCardText, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Do sieci: " + _toDisplayString(installation.powerToTheGrid), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_CCardText, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Całkowite zuzycie: " + _toDisplayString(installation.totalUsedPower), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_CCardText, null, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_5, _toDisplayString(installation.msg), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_CCardFooter, null, {
              default: _withCtx(() => [
                _createVNode(_component_CButton, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: '/admin/pv/' + installation.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Zobacz więcej")
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])
  ]))
}