<template>
  <CNav class="justify-content-center">
    <CNavItem v-for="(link, index) in currentNavLinks" :key="index">
      <CNavLink :active="link.active">
        <router-link :to="link.to">{{ link.text }}</router-link>
      </CNavLink>
    </CNavItem>
  </CNav>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CNav, CNavItem, CNavLink } from '@coreui/vue';

@Options({
components: {
    CNav, CNavItem, CNavLink
},
data() {
  return {
    endpointType: '',
    navLinks: [] as Array<{ text: string, to: string, active: boolean }>,
    settingsLinks: [] as Array<{ text: string, to: string, active: boolean }>,
    isSettings: false
  }
},
props: ['isPV'],
computed: {
  currentNavLinks() {
    if((this.$route.path).substring(1,15) == 'settings/admin')
    {
      this.isSettings = true
    }
  
    return this.isSettings ? this.settingsLinks : this.navLinks;
  }
},
methods: {
  setEndpoint()
  {
    if(this.isPV)
    {
      this.endpointType = 'pv'
    }else
    {
      this.endpointType = 'turbine'
    }
  },
  setupNavLinks() {
    this.navLinks = [
        { text: 'Produkcja', to: `/admin/${this.endpointType}/${this.$route.params.id}/`, active: this.$route.path.endsWith('/') },
        { text: 'Historyczna produkcja', to: `/admin/${this.endpointType}/${this.$route.params.id}/production/historic`, active: this.$route.path.includes('/production/historic') },
        { text: 'Statelista', to: `/admin/${this.endpointType}/${this.$route.params.id}/statelist`, active: this.$route.path.includes('/statelist') },
        { text: 'Informacje', to: `/admin/${this.endpointType}/${this.$route.params.id}/info`, active: this.$route.path.includes('/info') },
    ];
    this.settingsLinks = [
      { text: 'Komendy', to: `/settings/admin/commands`, active: this.$route.path.includes('/settings/command') },
      { text: 'Panel koszty', to: `settings/admin/cost-panel`, active: this.$route.path.includes('/settings/cost-panel') }
    ] 
}
},
created() {
  this.setEndpoint();
  this.setupNavLinks();
}
})
export default class ScadaNavComponent extends Vue {}
</script>
