<template>
    <h1>
        Rozliczenia
    </h1>
    <div class="data-inputs turbines">
        <h2>
            Wiatraki
        </h2>
        <CRow>
            <CCol md>
                <CFormLabel for="turbine-energy-sell-price">Cena sprzedaży energii</CFormLabel>
                <CFormInput type="number" class="turbine-energy-sell-price" v-model="turbines.energyPrice" min="0" step="0.01"/>
            </CCol>
            <CCol md>
                <CFormLabel for="turbine-certificates-price">Cena sprzedaży certyfikatów</CFormLabel>
                <CFormInput type="number" class="turbine-certificates-price" v-model="turbines.certificatePrice" min="0" step="0.01"/>
            </CCol>
        </CRow>
    </div>
    <div class="data-inputs pv">
        <h2>
            Instalacje PV
        </h2>
        <CRow>
            <CCol md>
                <CFormLabel for="pv-energy-sell-price">Cena zakupu energii</CFormLabel>
                <CFormInput type="number" class="pv-energy-buy-price" v-model="pvEnergyCostData.energyPrice" min="0" step="0.01"/>
            </CCol>
            <CCol md>
                <CFormLabel for="v-balancing-factor">Prosument bilansowanie(%)</CFormLabel>
                <CFormInput type="number" class="pv-balancing-factor" v-model="pvEnergyCostData.balancingFactor" min="0" step="1" max="100"/>
            </CCol>
        </CRow>
    </div>
    <hr>
    <date-picker-modal-component @pickedDates="fetchFromPickedDate"/>
    <hr>
    <div class="production-data" v-if="isLoaded">
        <div class="production-data-turbines">
            <h3>Turbiny</h3>
            <CRow>
                <CCol :md="4">
                    <CWidgetStatsF color="success" :padding="false" title="Produkcja" :value="turbines.totalProduction + 'MWh'">
                        <template #icon><CIcon :icon="cilBolt" height="36"/></template>
                </CWidgetStatsF>
                </CCol>
                <CCol :md="4">
                    <CWidgetStatsF color="primary" :padding="false" title="Przychód" :value="getTurbinesIncome() + 'zł'">
                        <template #icon><CIcon :icon="cilMoney" height="36"/></template>
                </CWidgetStatsF>
                </CCol>
            </CRow>
        </div>
        <div class="production-data-pv">
            <h3>PV</h3>
            <CRow class="production-data-pv-row">
                <CCol :md="4">
                    <CWidgetStatsF color="danger" :padding="false" title="Zużyta energia" :value="pv.totalUsedPower + 'MWh'">
                        <template #icon><CIcon :icon="cilIndustry" height="36"/></template>
                    </CWidgetStatsF>
                </CCol>
                <CCol :md="4">
                    <CWidgetStatsF color="warning" :padding="false" title="Do sieci" :value="pv.totalPowerToTheGrid/1000 + 'MWh'">
                        <template #icon><CIcon :icon="cilEco" height="36"/></template>
                    </CWidgetStatsF>
                </CCol>
                <CCol :md="4">
                    <CWidgetStatsF color="success" :padding="false" title="Produkcja PV" :value="pv.totalPowerFromPV/1000 + 'MWh'">
                        <template #icon><CIcon :icon="cilLightbulb" height="36"/></template>
                    </CWidgetStatsF>
                </CCol>
            </CRow>
            <CRow>
                <CCol :md="4">
                    <CWidgetStatsF color="primary" :padding="false" title="Koszty energii" :value="getPVTotalUsedPowerCost() + 'zł'">
                        <template #icon><CIcon :icon="cilMoney" height="36"/></template>
                    </CWidgetStatsF>
                </CCol>
                <CCol :md="4">
                    <CWidgetStatsF color="success" :padding="false" title="Oszczędności" :value="getPVSavings() + 'zł'">
                        <template #icon><CIcon :icon="cilMoney" height="36"/></template>
                    </CWidgetStatsF>
                </CCol>
            </CRow>
        </div>
    </div>
    <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
</template>

<style lang="scss" scoped>
.data-inputs, .production-data
{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
    &-pv-row {
        margin-bottom: 1rem;
    }
}
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { BodyParams } from '@/interfaces/body';
import { CSpinner, CRow, CCol, CFormLabel, CFormInput, CWidgetStatsF } from '@coreui/vue';
import { CIcon } from '@coreui/icons-vue';
import { cilBolt, cilEco,cilIndustry,cilLeaf, cilLightbulb, cilMoney } from '@coreui/icons';
import DatePickerModalComponent from '@/components/DatePickerModalComponent.vue';
import store from '@/store';

@Options({
data() {
    return {
        cilBolt, cilEco,cilIndustry,cilLeaf, cilLightbulb, cilMoney, //icons
        isLoaded: false,
        today: '',
        turbines: {
            totalProduction: 0,
            turbines: [],
            energyPrice: 400,
            certificatePrice: 60
        },
        pvEnergyCostData: {
            energyPrice: 850,
            balancingFactor: 80,
        },
        pv: {
            totalProduction: 0,
            totalUsedPower: 0,
            totalPowerFromPV: 0,
            totalPowerToTheGrid: 0,
            pvInstallations: [
                {
                success: false,
                installation: '',
                msg: '',
                powerFromGrid: 0,
                powerFromPV: 0,
                powerToTheGrid: 0,
                totalUsedPower: 0
                }
            ],
        }
    };
},
components: {
    DatePickerModalComponent, CSpinner, CRow, CCol, CFormLabel, CFormInput, CWidgetStatsF, CIcon, 
},
methods: {
    async getVensysProduction(dates: BodyParams|null)
    {
      let body = {};
      if(dates != null)
      {
        body = {
          'startDate': dates.startDate,
          'endDate': store.getters.getDate('+1day', dates.endDate).substring(0,10)
        }
      }else
      {
        body = {
            'startDate': store.getters.getDate().substring(0,8)+'01',
            'endDate': store.getters.getDate().substring(0,8)+'31'
        }
      }
      let data = await store.dispatch({
        'type': 'postRequest',
        'link': 'production/vensys',
        'body': body
      })
      if(!data.success)
      {
        alert('Problem while retriving data from API')
      }
      this.turbines.turbines = data.data;
      this.turbines.totalProduction =  Math.round((data.totalProduction/1000 * 100))/100;
    },
    async getHuaweiProduction(dates: BodyParams|null)
    {
      let body = {};
      if(dates != null)
      {
        body = {
          'startDate': dates.startDate,
          'endDate': store.getters.getDate(null, dates.endDate).substring(0,10)
        }
      }else
      {
        body = {
            'startDate': store.getters.getDate().substring(0,8)+'01',
            'endDate': store.getters.getDate().substring(0,8)+'31'
        }
      }
      
      let data = await store.dispatch({
        'type': 'postRequest',
        'link': 'production/pv/huawei',
        'body': body
      })

      if(!data.success)
      {
        alert('Problem while retriving data from API')
      }

      this.pv.pvInstallations = data.pvInstallations;
      this.pv.totalProduction +=  Math.round((data.totalProduction/1000 * 100))/100;
      this.pv.totalUsedPower +=  Math.round((data.totalUsedPower/1000 * 100))/100;
      this.pv.totalPowerFromPV +=  Math.round((data.totalPowerFromPV));
      this.pv.totalPowerToTheGrid +=  Math.round((data.totalPowerToTheGrid));
    },
    async getSolarmanProduction(dates: BodyParams|null)
    {
      let body = {};
      if(dates != null)
      {
        body = {
          'startDate': dates.startDate,
          'endDate': store.getters.getDate(null, dates.endDate).substring(0,10)
        }
      }else
      {
        body = {
            'startDate': store.getters.getDate().substring(0,8)+'01',
            'endDate': store.getters.getDate().substring(0,8)+'31'
        }
      }
      
      let data = await store.dispatch({
        'type': 'postRequest',
        'link': 'production/pv/solarman',
        'body': body
      })

      if(!data.success)
      {
        alert('Problem while retriving data from API')
      }

      this.pv.pvInstallations = data.pvInstallations;
      this.pv.totalProduction +=  Math.round((data.totalProduction/1000 * 100))/100;
      this.pv.totalUsedPower +=  Math.round((data.totalUsedPower/1000 * 100))/100;
      this.pv.totalPowerFromPV +=  Math.round((data.totalPowerFromPV));
      this.pv.totalPowerToTheGrid +=  Math.round((data.totalPowerToTheGrid));
    },
    async fetchFromPickedDate(pickedDates: BodyParams)
    {
      this.isLoaded = false;
      const endDate = new Date(pickedDates.endDate);
      const startDate = new Date(pickedDates.startDate);
      const timeDifference = endDate.getTime() - startDate.getTime();
      const differenceInDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
      if(differenceInDays > 31)
      {
        alert('Zakres dat nie może przekroczyć 31 dni')
        this.isLoaded = true;
        return;
      }
      if(pickedDates.startDate == pickedDates.endDate)
      {
        this.today = `${pickedDates.startDate}`
      }else
      {
        this.today = `Od: ${pickedDates.startDate} do ${pickedDates.endDate}`
      }

      const test = {...pickedDates};
      await this.getVensysProduction(test)
      await this.getHuaweiProduction(test)
      await this.getSolarmanProduction(test)
      this.isLoaded = true;
    },
    getTurbinesIncome()
    {
        let totalPrice = parseFloat(this.turbines.energyPrice) + parseFloat(this.turbines.certificatePrice);
        return Math.round(this.turbines.totalProduction * totalPrice);
    },
    getPVTotalUsedPowerCost()
    {
        let totalPrice = parseFloat(this.pvEnergyCostData.energyPrice);
        return Math.round((parseFloat(this.pv.totalUsedPower) - (parseFloat(this.pv.totalPowerToTheGrid)/1000) * (parseFloat(this.pvEnergyCostData.balancingFactor)/100) )* totalPrice);
    },
    getPVSavings()
    {
        let powerToTheGrid = parseFloat(this.pv.totalPowerToTheGrid)/1000 * parseFloat(this.pvEnergyCostData.balancingFactor)/100;
        let powerFromPV = parseFloat(this.pv.totalPowerFromPV)/1000;
        return Math.round((powerFromPV + powerToTheGrid) * parseFloat(this.pvEnergyCostData.energyPrice))
    }
},
async created() {
    this.isLoaded = false;
    await this.getVensysProduction()
    await this.getSolarmanProduction()
    await this.getHuaweiProduction()
    this.isLoaded = true;
}
})
export default class BillingView extends Vue {}
</script>

