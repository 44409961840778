<template>
<div class="command-list card-container" v-if="isLoaded">
    <CCard v-for="command in commandList" v-bind:key="command.id">
        <CCardHeader>
            {{ command.commandName }}
        </CCardHeader>
        <CCardBody>
            <CCardText>
                Odbiorcy email: 
                <ul>
                    <li v-for="email in command.emails">{{ email }}</li>
                </ul>
            </CCardText>
            <CCardText>
                Odbiorcy SMS: 
                <ul>
                    <li v-for="smsReciver in command.smsNumbers">{{ smsReciver }}</li>
                </ul>
            </CCardText>
        </CCardBody>
        <CCardFooter>
            <CButton>
                <router-link :to="'commands/' + command.id + '/setup'">Zmień ustawienia</router-link>
            </CButton>
        </CCardFooter>
    </CCard>
</div>
<CSpinner color="success" v-else class="spinner"/>
</template>

<style lang="scss" scoped>
/* For ultra-wide displays */
@media (min-width: 1600px) {
    .card-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr); /* 8 cards in a row */
    gap: 16px; /* Adjust the gap between cards */
    }
}

/* For large displays */
@media (min-width: 1200px) and (max-width: 1599px) {
    .card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 cards in a row */
    gap: 16px; /* Adjust the gap between cards */
    }
}

/* For medium displays */
@media (min-width: 768px) and (max-width: 1199px) {
    .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 cards in a row */
    gap: 16px; /* Adjust the gap between cards */
    }
}

/* For small displays (e.g. mobile phones) */
@media (max-width: 767px) {
    .card-container {
    display: block; /* Cards stack vertically on small screens */
    }
}
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CCard, CCardHeader, CCardBody, CCardFooter, CButton, CCardText, CSpinner } from '@coreui/vue';
import store from '@/store';

@Options({
components: {
    CCard, CCardHeader, CCardBody, CCardFooter, CButton, CCardText, CSpinner
},
data() {
    return {
        commandList: [
            {
                id: 0,
                commandName: '',
                smsNumbers: [],
                emails: []
            }
        ],
        isLoaded: false
    }
},
methods: {
    async getCommandList()
    {
        let data = await store.dispatch({
        'type': 'getRequest',
        'link': 'settings/command'
        })   
        if(! await data.success)
        {
            this.msg = data.msg
            return;
        }
        this.commandList = data.commands
    }
},
async created() {
    this.isLoaded = false;
    await this.getCommandList()
    this.isLoaded = true;
}
})
export default class CommandListComponent extends Vue {}
</script>
