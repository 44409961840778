<template>
    <CButton color="success" @click="() => { showPicker = true }">{{ options.buttonText }}</CButton>
    <CModal alignment="center" :visible="showPicker" @close="() => { showPicker = false }">
        <CModalHeader>
            <CModalTitle>{{ options.title }} </CModalTitle>
        </CModalHeader>
        <CModalBody>
            <div class="sms-receivers" v-if="isSmsReceiver">
                SMS
                <CInputGroup v-for="(receiver, index) in receivers">
                    <CFormInput
                    :key="index"
                    v-model="receivers[index]"
                    type="text"
                    placeholder="+48111222333"
                    autocomplete="phoneNumber"
                    />
                    <CInputGroupText>
                        <CButton @click="deleteReceiver(index)">
                            <i class="fa-solid fa-trash"></i>
                        </CButton>
                    </CInputGroupText>
                </CInputGroup>
                <p v-if="msg.length > 0">
                    <hr>
                    {{ msg }}
                </p>
            </div>
            <div class="email-receivers" v-else>
                Emails
                <div v-for="(email, index) in userEmails" :key="index">
                    <input
                        type="checkbox"
                        :value="email"
                        v-model="checkedEmails"
                        @change="toggleEmail()"
                    >
                    {{ email }}
                </div>
            </div>
        </CModalBody>
        <CModalBody v-if="isSmsReceiver">
            <CButton color="success" @click="addReceiver" >Dodaj użytkownika</CButton>
            <CButton color="secondary" @click="resetReceivers">Usuń wszystkich</CButton>
        </CModalBody>
        <CModalFooter>
            <CButton color="secondary" @click="() => { showPicker = false }">
                Zamknij
            </CButton>
            <CButton color="success" @click="setReceivers">Zapisz zmiany</CButton>
        </CModalFooter>
    </CModal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CButton, CModal, CModalHeader, CModalTitle, CModalBody, CFormLabel, CModalFooter, CFormInput, CImage, CInputGroup, CInputGroupText } from '@coreui/vue';
import { SetupModalOptions } from '@/interfaces/command';
import type { PropType } from 'vue';

@Options({
components: {
    CButton, CModal, CModalHeader, CModalTitle, CModalBody, CFormLabel, CModalFooter, CFormInput, CImage, CInputGroup, CInputGroupText
},
data() {
    return {
        showPicker: false,
        msg: '',
        checkedEmails: []
    }
},
props: {
    options: Object as PropType<SetupModalOptions>,
    receivers: { type: Array as PropType<string[]>, default: () => [] },
    userEmails: { type: Array as PropType<string[]>, default: () => [] },
    isSmsReceiver: Boolean,
},
methods: {
    addReceiver() 
    {
        this.msg = '';
        this.receivers.push('');
        this.$emit('update-receivers', {receivers: this.receivers, isSmsReceiver: true, tempChanges: true});
    },
    deleteReceiver(index: Number)
    {
        this.receivers.splice(index, 1);
        this.$emit('update-receivers', {receivers: this.receivers, isSmsReceiver: true, tempChanges: true});
    },
    resetReceivers() 
    {
        this.receivers.splice(0,this.receivers.length)
        this.msg = 'Usunięto wszystkich odbiorców. Jeśli tego nie chciałeś nie zapisuj zmian'
        alert('Usunięto wszystkich odbiorców');
    },
    setReceivers() 
    {
        if(this.isSmsReceiver)
        {
            this.$emit('update-receivers', {receivers: this.receivers, isSmsReceiver: true, tempChanges: false});
        }else
        {
            this.$emit('update-receivers', {receivers: this.checkedEmails, isSmsReceiver: false, tempChanges: false});
        }
        this.showPicker = false;
    },
    toggleEmail()
    {
        return this.checkedEmails
    },
},
emits: ['update-receivers'],
async created() {
    this.checkedEmails = this.receivers
}
})
export default class CommandSetupModalComponent extends Vue {}
</script>