import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CNavLink = _resolveComponent("CNavLink")!
  const _component_CNavItem = _resolveComponent("CNavItem")!
  const _component_CNav = _resolveComponent("CNav")!

  return _withDirectives((_openBlock(), _createBlock(_component_CNav, {
    class: "justify-content-center",
    variant: "tabs"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CNavItem, null, {
        default: _withCtx(() => [
          _createVNode(_component_CNavLink, {
            active: _ctx.isActive('/'),
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => [
                  _createTextVNode("Home")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["active"])
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_component_CNavItem, null, {
        default: _withCtx(() => [
          _createVNode(_component_CNavLink, {
            active: _ctx.isActive('/billing'),
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: "/billing" }, {
                default: _withCtx(() => [
                  _createTextVNode("Rozliczenia")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["active"])
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.isAdmin]
      ]),
      _withDirectives(_createVNode(_component_CNavItem, null, {
        default: _withCtx(() => [
          _createVNode(_component_CNavLink, {
            active: _ctx.isActive('/settings/admin'),
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: "/settings/admin" }, {
                default: _withCtx(() => [
                  _createTextVNode("Admin Settings")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["active"])
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.isAdmin]
      ]),
      _createVNode(_component_CNavItem, null, {
        default: _withCtx(() => [
          _createVNode(_component_CNavLink, {
            active: _ctx.isActive('/login'),
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: "/login",
                onClick: _ctx.logout
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Logout")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["active"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512)), [
    [_vShow, _ctx.showNav]
  ])
}