import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pv" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_installations_view = _resolveComponent("installations-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createTextVNode(" User Display "),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isObjectLoaded(_ctx.production.pvProduction.pvHuawei))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_installations_view, {
              installations: _ctx.production.pvProduction.pvHuawei
            }, null, 8, ["installations"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isObjectLoaded(_ctx.production.pvProduction.pvSolarman))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_installations_view, {
              installations: _ctx.production.pvProduction.pvSolarman
            }, null, 8, ["installations"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}