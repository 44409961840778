import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54f086b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "command-list card-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CCardHeader = _resolveComponent("CCardHeader")!
  const _component_CCardText = _resolveComponent("CCardText")!
  const _component_CCardBody = _resolveComponent("CCardBody")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CButton = _resolveComponent("CButton")!
  const _component_CCardFooter = _resolveComponent("CCardFooter")!
  const _component_CCard = _resolveComponent("CCard")!
  const _component_CSpinner = _resolveComponent("CSpinner")!

  return (_ctx.isLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commandList, (command) => {
          return (_openBlock(), _createBlock(_component_CCard, {
            key: command.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CCardHeader, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(command.commandName), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_CCardBody, null, {
                default: _withCtx(() => [
                  _createVNode(_component_CCardText, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Odbiorcy email: "),
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(command.emails, (email) => {
                          return (_openBlock(), _createElementBlock("li", null, _toDisplayString(email), 1))
                        }), 256))
                      ])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_CCardText, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Odbiorcy SMS: "),
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(command.smsNumbers, (smsReciver) => {
                          return (_openBlock(), _createElementBlock("li", null, _toDisplayString(smsReciver), 1))
                        }), 256))
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_CCardFooter, null, {
                default: _withCtx(() => [
                  _createVNode(_component_CButton, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: 'commands/' + command.id + '/setup'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Zmień ustawienia")
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]))
    : (_openBlock(), _createBlock(_component_CSpinner, {
        key: 1,
        color: "success",
        class: "spinner"
      }))
}