<template>
    <h2>
        Historyczna produkcja dla <span>WEA{{ $route.params.id }}</span>
    </h2>
    <hr>
    <date-picker-modal-component @pickedDates="fetchFromPickedDate"/>
    <hr>
    <h4>Od {{ turbine.startDate }} Do: {{ turbine.endDate }}</h4>
    <div class="historic-production-table" v-show="isLoaded" v-if="turbine.noErrors">
        <CTable striped :columns="columns" :items="turbine.data" />
    </div>
    <p v-else>
        {{ msg }}
    </p>
    <CSpinner color="success" v-show="!isLoaded" class="spinner"/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { BodyParams } from '@/interfaces/body';
import { CSpinner, CTable } from '@coreui/vue';
import DatePickerModalComponent from '@/components/DatePickerModalComponent.vue';
import store from '@/store';

@Options({
    components: {
        DatePickerModalComponent, CSpinner, CTable
    },
    data() {
        return {
            isLoaded: false,
            columns: [
                {
                    key: 'date',
                    label: 'Data',
                    _props: { scope: 'col' },
                },
                {
                    key: 'totalProduction',
                    label: 'Produkcja (kWh)',
                    _props: { scope: 'col' },
                },
                {
                    key: 'avgWindSpeed',
                    label: 'Średni wiatr(m/s)',
                    _props: { scope: 'col' },
                },
            ],
            turbine: {
                id: this.$route.params.id,
                name: '',
                data: [
                {
                    date: '',
                    totalProduction: 0,
                    avgWindSpeed: 0,
                }
                ],
                noErrors: true,
                startDate: '',
                endDate: ''
            },
            msg: ''
        }
    },
    methods: {
        async getVensysProduction(dates: BodyParams)
        {            
            let datesInterval = store.getters.getIntervalBetweenDates(dates.startDate, dates.endDate)
            if(datesInterval.length == 0)
            {
                //Alert to change for CToast
                alert('Data początkowa musi być mniejsza od końcowej')
                return;
            }

            if(datesInterval.length > 100)
            {
                //To test efficiency and execution time of the request
                alert('Dane nie mogą pochodzić z więcej niż 100 dni')
                return;
            }

            let data = await store.dispatch({
                'type': 'postRequest',
                'link': 'production/vensys/'+this.turbine.id+'/historic',
                'body': dates
            })
            if(! await data.success)
            {
                this.msg = data.msg
                this.turbine.noErrors = false;
                return;
            }
            this.turbine = await data
            this.turbine.noErrors = true;
            
        },
        async fetchFromPickedDate(pickedDates: BodyParams)
        {
            this.isLoaded = false;
            const dates = {...pickedDates};
            this.turbine.production = []
            await this.getVensysProduction(dates)
            this.isLoaded = true;
        }
    },
    async created() {
        this.isLoaded = false;
        await this.getVensysProduction({
            startDate: store.getters.getDate('-7days', null).substring(0,10),
            endDate: store.getters.getDate().substring(0,10)
        })
        this.isLoaded = true;
    }
})
export default class VensysHistoricProductionComponent extends Vue {}
</script>

<style scoped>
.historic-production-table {
    width: 90%;
    margin: auto;
}
</style>