<template>
    <div class="command-setup" v-if="isLoaded">
        <h1>Komenda: {{ command.commandName }}</h1>
        <div class="command-setup-sms-recivers">
            Akutalni Odbiorcy SMS: 
            <ul>
                <li v-for="smsNumber in command.smsNumbers">{{ smsNumber }}</li>
            </ul>
            <div class="command-setup-add-sms-recivers">
                <setup-modal :options="{
                    buttonText: 'Dodaj odbiorców',
                    title: 'Odbiorcy SMS'
                }" :receivers="command.smsNumbers == null ? [] : command.smsNumbers.slice()"
                :isSmsReceiver="true" @update-receivers="updateReceivers"
                />
            </div>
        </div>
        <div class="command-setup-email-recivers">
            Akutalni Odbiorcy Email: 
            <ul>
                <li v-for="email in command.emails">{{ email }}</li>
            </ul>
            <div class="command-setup-add-sms-recivers">
                <setup-modal :options="{
                    buttonText: 'Dodaj odbiorców',
                    title: 'Odbiorcy SMS'
                }"
                :receivers="command.emails == null ? [] : command.emails.slice()" 
                :userEmails="users.userEmails"
                @update-receivers="updateReceivers"/>
            </div>
        </div>
    </div>
    <CSpinner color="success" v-else class="spinner"/>
</template>
    
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CCard, CCardHeader, CCardBody, CCardFooter, CButton, CCardText, CSpinner } from '@coreui/vue';
import { Command } from '@/interfaces/command';
import SetupModal from '@/components/settings/command/SetupModal.vue'
import store from '@/store';

@Options({
components: {
    SetupModal, CCard, CCardHeader, CCardBody, CCardFooter, CButton, CCardText, CSpinner
},
data() {
    return {
        command:{
            id: this.$route.params.id,
            commandName: '',
            smsNumbers: [],
            emails: []
        },
        users: {
            success: true,
            userEmails: []
        },
        isLoaded: false
    }
},
methods: {
    updateReceivers(newReceivers: {
        receivers: string[],
        isSmsReceiver: Boolean,
        tempChanges: Boolean
    }) {
        if(newReceivers.isSmsReceiver)
        {
            this.command.smsNumbers = newReceivers.receivers
            if(!newReceivers.tempChanges)
            {
                const routeId = Number(this.$route.params.id); 
                this.postRequest('settings/command/'+routeId+'/setup');
            }
        }else
        {
            this.command.emails = newReceivers.receivers
            if(!newReceivers.tempChanges)
            {
                const routeId = Number(this.$route.params.id); 
                this.postRequest('settings/command/'+routeId+'/setup');
            }
        }
    },
    async getCommandList()
    {
        const data = await this.getRequest('settings/command');
        const routeId = Number(this.$route.params.id); 
        const commandList = await data.commands
        let command = commandList.find((command: Command) => command.id === routeId);
        if(command != undefined)
        {
            this.command = command;
        }else
        {
            alert('Brak komedny o podanym ID')
            return;
        }
    },
    async getRequest(link:string)
    {
        let data = await store.dispatch({
        'type': 'getRequest',
        'link': link
        })   
        if(! await data.success)
        {
            this.msg = data.msg
            return;
        }
        return data;
    },
    async postRequest(link: string)
    {
        const body = {
            phoneNumbers: this.command.smsNumbers.length == 0 ? null : this.command.smsNumbers,
            emails: this.command.emails.length == 0 ? null : this.command.emails
        }; 
        let data = await store.dispatch({
        'type': 'postRequest',
        'link': link,
        'body': body
        })   
        if(!await data.success)
        {
            this.msg = data.msg
            return;
        }
        return data;
    }
},
async created() {
    this.isLoaded = false;
    this.getCommandList();
    this.users = await this.getRequest('users')
    this.isLoaded = true;
}
})
export default class CommandSetupComponent extends Vue {}
</script>
    